import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';

// Utils
import DateUtil from 'Utils/date-util';
import { getOriginalItem } from 'Utils/content/item-util';

// Components
import Visible from 'Webapp/shared/app/components/visible';
import QuoteIcon from 'ComponentLibrary/icons/quote';
import ItemLink, { elementTypes as itemLinkElementTypes } from './item-link';
import PostAttribution from '../attribution/post';
import SocialActions from '../social-actions';
import ItemStructuredData from '../../structured-data/item';
import ItemCardMedia from './card/media';
import ItemCardTitle from './card/title';
import ItemCardExcerpt from './card/excerpt';
import ItemCardSourceAttribution from './card/source-attribution';
import ItemCardSectionDescription from './card/section-description';
import MutedItemCard from 'Webapp/shared/app/components/item/muted-item-card';
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';
import ItemCardCoreActions from './item-card-core-actions';

import ItemFlipNote from 'Webapp/shared/app/components/item/item-flip-note';
import CuratorProMagazineItemCardControls from 'Webapp/shared/app/components/curator-pro/magazine/item-card-controls';
import DateFlipped from 'Webapp/shared/app/components/curator-pro/date-flipped';

import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';

const StyledItemCard = styled.article({
  display: 'grid',
  gap: SPACING.LARGE,
});
/* Header Components */
const PostHeaderWrapper = styled('header', {
  shouldForwardProp: (prop) => prop !== 'hasCaption',
})((props) => ({
  backgroundColor: props.hasCaption ? SURFACE_COLORS.secondary : 'inherit',
  borderRadius: `${SPACING.BASE} ${SPACING.BASE} 0 0`,
  display: 'grid',
  gap: SPACING.LARGE,
  padding: props.hasCaption
    ? SPACING.LARGE
    : `${SPACING.LARGE} ${SPACING.LARGE} 0`,
}));

const PostHeader = styled.header({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  gridTemplateRows: 'auto 1fr',
});

const PostOverflowActions = styled(SocialActions)({
  marginLeft: 'auto',
});

/* Main Magazine Components */
const PostBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: SPACING.LARGE,
  padding: `0 ${SPACING.LARGE}`,
});

const StyledMediaElement = styled(ItemCardMedia)({
  borderRadius: SPACING.BASE,
  // a bunch of stuff that needs converted to css-in-js
  '.media-container-fallback': {
    borderRadius: 'inherit',
  },
  '.section-tiles__tile-overlay': {
    borderRadius: SPACING.BASE,
  },
  '.section-tiles__image': {
    borderRadius: SPACING.BASE,
    '.cropped-image': {
      borderRadius: 'inherit',
    },
  },
});

const PostTime = styled.div(BODY_TYPES.SMALL_STANDARD, {
  color: TEXT_COLORS.tertiary,
});

/* Magazine Social Actions/Footer */
const PostFooter = styled.footer({
  display: 'grid',
  alignContent: 'end',
  padding: `${SPACING.BASE4X} ${SPACING.LARGE} ${SPACING.LARGE}`,
});

const PostReadMore = styled.div({
  margin: `${SPACING.BASE6X} auto ${SPACING.XLARGE}`,
  textAlign: 'center',
});

class ItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      lastKnownHeight: null,
    };
    this.cardRef = React.createRef();
  }
  render() {
    const {
      item,
      section,
      truncateTitleLength,
      truncateExcerptLength,
      showExcerpt,
      showReadMore,
      shownInList,
      useCrop,
      windowHeight,
      isAmp,
      imageTheme,
      isCuratorProView,
      t,
      lang,
    } = this.props;
    // We may want to render the "original" item, as in the case of
    // "status" items
    let itemToRender = item;
    if (item.isStatus) {
      itemToRender = getOriginalItem(item) || item;
    }
    if (item.isActivity) {
      itemToRender = item.refersTo;
    }
    if (!itemToRender) {
      return null;
    }

    const { sanitizedTitle, sourceDomain, isSection } = itemToRender;

    // Some items are incomplete - do not render when there is missing content
    if (
      sanitizedTitle === null &&
      item.excerpt === null &&
      !itemToRender.isImage
    ) {
      return null;
    }
    const timeElement = !itemToRender.hideTimelineDate && (
      <PostTime key={10}>
        <time>
          {DateUtil.friendlyFormat(itemToRender.dateCreated, t, lang)}
        </time>
      </PostTime>
    );
    const titleElement = (
      <ItemCardTitle
        item={itemToRender}
        section={section}
        shownInList={shownInList}
        truncateTitleLength={truncateTitleLength}
        key={6}
      />
    );
    const quoteElement = itemToRender.isStatus && titleElement && (
      <QuoteIcon key={5} />
    );

    const duplicatedExerpt =
      itemToRender?.sanitizedTitle === itemToRender?.excerpt?.trim();
    const excerptElement = showExcerpt && !isSection && !duplicatedExerpt && (
      <ItemCardExcerpt
        item={itemToRender}
        section={section}
        shownInList={shownInList}
        truncateExcerptLength={truncateExcerptLength}
        key={8}
      />
    );

    const sectionDescriptionElement = showExcerpt && isSection && (
      <ItemCardSectionDescription
        item={itemToRender}
        section={section}
        shownInList={shownInList}
        key={9}
      />
    );

    const publisherSite = sourceDomain ? sourceDomain : 'publisher site';
    const readMoreElement = !shownInList &&
      showReadMore &&
      !itemToRender.isFirstPartyVideo &&
      !itemToRender.isStatus && (
        <PostReadMore key={11}>
          <ItemLink
            item={itemToRender}
            shownInList={shownInList}
            elementType={itemLinkElementTypes.BUTTON}
            buttonName="item-view-on-publisher-site"
            buttonLabel={t('view_on_publisher_site', {
              publisherSite,
            })}
          />
        </PostReadMore>
      );

    const mediaElement = (
      <StyledMediaElement
        item={itemToRender}
        section={section}
        shownInList={shownInList}
        isAmp={isAmp}
        useCrop={useCrop}
        windowHeight={windowHeight}
        key={3}
        imageTheme={imageTheme}
      />
    );

    const sourceAttributionElement = (
      <ItemCardSourceAttribution
        item={itemToRender}
        section={section}
        key={7}
      />
    );

    // Item render contents
    const itemContents = [
      !shownInList && !item.isSection && (
        <ItemStructuredData
          item={itemToRender}
          section={section}
          t={t}
          key={0}
        />
      ),
      mediaElement,
      timeElement,
      quoteElement,
      titleElement,
      !item?.section?.isTopic &&
        !item?.section?.isProfile &&
        sourceAttributionElement,
      excerptElement,
      sectionDescriptionElement,
      readMoreElement,
    ];

    const postClasses = classNames({
      post: true,
      'post--card': true,
      'post--section': isSection,
      'post-grid': shownInList,
      'post--article-view': !shownInList,
    });

    if (shownInList) {
      if (!this.state.visible) {
        // if the card isn't in the viewport (or even close really) we
        // just render an empty div which is the same height
        return (
          <div style={{ height: `${this.state.lastKnownHeight}px` }}>
            <Visible bufferTop={1000} bufferBottom={1000}>
              {(visible) => this.setState({ visible })}
            </Visible>
          </div>
        );
      }
      if (item.recentlyMuted) {
        return <MutedItemCard item={item} section={section} />;
      }

      const hideHeader = item.isClientSideFeedItem;

      const { caption, original, text } = item;
      const captionText = caption || (original && text);

      return (
        <React.Fragment>
          <Visible bufferTop={2000} bufferBottom={2000}>
            {(visible) => {
              this.setState({
                visible,
                lastKnownHeight: this.cardRef?.current.offsetHeight,
              });
            }}
          </Visible>
          <StyledItemCard
            ref={this.cardRef}
            className={postClasses}
            key={itemToRender.id}
          >
            {!hideHeader && (
              <PostHeaderWrapper hasCaption={!!captionText}>
                {isCuratorProView && (
                  <PostHeader>
                    <DateFlipped item={item} lang={lang} />
                    <CuratorProMagazineItemCardControls
                      item={item}
                      section={section}
                    />
                  </PostHeader>
                )}
                <ItemFlipNote item={item} isItemCaption />
                {!isCuratorProView && (
                  <PostHeader>
                    <PostAttribution item={itemToRender} section={section} />
                    <PostOverflowActions
                      section={section}
                      item={itemToRender}
                      itemCardVariant
                      buttonSet={[]}
                      anchoredDialogStyleVariation={
                        AnchoredDialog.styleVariations.MENU_FULL_WIDTH
                      }
                    />
                  </PostHeader>
                )}
              </PostHeaderWrapper>
            )}
            <PostBody className="post__body">{itemContents}</PostBody>
            <PostFooter>
              <ItemCardCoreActions
                section={section}
                item={item}
                buttonSet={BUTTON_SETS.NEW_ITEM_CARD}
                showSocialMetrics
              />
            </PostFooter>
          </StyledItemCard>
        </React.Fragment>
      );
    }

    // For improved HTML sectioning hierarchy, article preview pages should not use the article tag
    return (
      <div className={postClasses} key={itemToRender.id}>
        <PostAttribution item={itemToRender} section={section} />
        {itemContents}
        <SocialActions
          section={section}
          item={itemToRender}
          itemCardVariant
          anchoredDialogStyleVariation={
            AnchoredDialog.styleVariations.MENU_FULL_WIDTH
          }
        />
      </div>
    );
  }
}

ItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  isAmp: PropTypes.bool,
  section: PropTypes.object,
  truncateTitleLength: PropTypes.number,
  truncateExcerptLength: PropTypes.number,
  showExcerpt: PropTypes.bool,
  showReadMore: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  shownInList: PropTypes.bool,
  useCrop: PropTypes.bool,
  windowHeight: PropTypes.number,
  imageTheme: PropTypes.func,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  isCuratorProView: PropTypes.bool.isRequired,
};

ItemCard.defaultProps = {
  isAmp: false,
  section: null,
  truncateTitleLength: null,
  truncateExcerptLength: null,
  showExcerpt: false,
  showReadMore: false,
  shownInList: true,
  useCrop: true,
  windowHeight: null,
  isCuratorProView: false,
};

export default connector(
  connectAmp,
  connectAuthentication,
  connectResponsive,
  connectLanguage,
)(withT(ItemCard));
