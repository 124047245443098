import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withT from 'ComponentLibrary/hocs/withT';
import withFollow from 'Webapp/shared/app/hocs/withFollow';

// Components
import FollowButton from 'Webapp/shared/app/components/follow-button';
import { StyleVariations } from 'Webapp/shared/app/components/button';

const MAX_TOPICS = 16;

/* Converted to Component from Function since we need to keep the state persistent when
users follow/unfollow topics during the session (on the inital load, we
filter out already-followed topics). Otherwise, when users follow a topic, the topic itself
will not render
*/
class GeneratedRelatedTopicItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredTopics: [],
    };
  }

  componentDidMount() {
    const { item, followingIds, filterUnfollowed } = this.props;
    let topics = item.isSmartMagazine ? item.subsections : item.topics;
    if (followingIds.length) {
      topics = filterUnfollowed(topics)?.slice(0, MAX_TOPICS);

      this.setState({
        filteredTopics: topics,
      });
    } else {
      this.setState({
        filteredTopics: topics?.slice(0, MAX_TOPICS),
      });
    }
  }

  render() {
    const { filteredTopics } = this.state;
    const { item, t } = this.props;
    return (
      <div
        className="generated-feed-item-card generated-feed-item-card__topic"
        key={item.id}
      >
        <span className="generated-feed-item-card__topic__title">
          {t('discover_more_topics', {
            topicTitle: item.title,
          })}
        </span>
        <div>
          {filteredTopics.map((topic) => (
            <FollowButton
              key={topic.title}
              namePrefix="generated-related-topic-item"
              section={topic}
              label={topic.title}
              disablePersonalization
              styleVariation={StyleVariations.UNFOLLOWED_TOPIC}
              followingStyleVariation={StyleVariations.FOLLOWED_TOPIC}
            />
          ))}
        </div>
      </div>
    );
  }
}

GeneratedRelatedTopicItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  followingIds: PropTypes.array,
  filterUnfollowed: PropTypes.func,
};

GeneratedRelatedTopicItemCard.defaultProps = {};

export default withT(withFollow(GeneratedRelatedTopicItemCard));
