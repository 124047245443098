import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Utils
import DateUtil from 'Utils/date-util';
import { UI_TEXT_TYPES } from 'Style/typography';
import withT from 'ComponentLibrary/hocs/withT';

export const StyledDateFlipped = styled.div(UI_TEXT_TYPES.SUPPORTING);

function DateFlipped({ className, item, lang, t }) {
  const { dateFlipped } = item;
  if (!dateFlipped) {
    return null;
  }
  return (
    <StyledDateFlipped className={className}>
      <time>
        {t('flipped_datetime_absolute', {
          datetime: DateUtil.completeFormat(dateFlipped, lang, true),
        })}
      </time>
    </StyledDateFlipped>
  );
}

DateFlipped.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  lang: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withT(DateFlipped);
