import connector from 'Utils/connector';
import SectionItemDisplay from 'Webapp/shared/app/components/section-item-display';
import {
  usageIncrementSectionHeartbeatItemsDisplayed,
  usageTrackDisplayItem,
} from 'Webapp/shared/app/redux/actions/usage-actions';

export default connector({
  actions: {
    usageIncrementSectionHeartbeatItemsDisplayed,
    usageTrackDisplayItem,
  },
})(SectionItemDisplay);
