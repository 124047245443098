import React from 'react';
import PropTypes from 'prop-types';
import ItemLink from '../item-link';

function ItemCardSectionDescription({ item, section, shownInList }) {
  if (!item.section || !item.section.description) {
    return null;
  }

  return (
    <p className="post__excerpt">
      <ItemLink item={item} section={section} shownInList={shownInList}>
        {item.section.description}
      </ItemLink>
    </p>
  );
}

ItemCardSectionDescription.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  shownInList: PropTypes.bool,
};

ItemCardSectionDescription.defaultProps = {
  shownInList: false,
};

export default ItemCardSectionDescription;
