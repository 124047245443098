import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Utils
import {
  imageUrl,
  largestUrl,
  processFlippusRawImages,
} from 'Utils/image-util';
import { generateCuratorProMagazineButtonName } from '../../../../../client/ga/buttons';

import { SPACING } from 'Style/spacing';
import { COLORS, DIVIDER_COLORS } from 'Style/colors';

// Components
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import Label from 'ComponentLibrary/forms/label';
import ModalWrapper from '../../modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import {
  MODAL_THEMES,
  MODAL_WINDOW_SIZES,
} from 'ComponentLibrary/modals/modal';
import ImageSelector from '../../../components/curator-pro/image-selector';
import Message from 'ComponentLibrary/typography/message';
import ConfirmDismiss from '../../confirm-dismiss';
import ItemCondensed from '../../../components/item/item-condensed';
import ArticleItemOverrideForm from '../../../components/curator-pro/article-item-override-form';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectCuratorProMagazine from 'Webapp/shared/app/connectors/connectCuratorProMagazine';

const TITLE_LENGTH_LIMIT = 500;

const ItemWrapper = styled.div({
  padding: `${SPACING.BASE} 0`,
});

const ButtonsList = styled.ul({
  marginBottom: SPACING.BASE4X,
});

const ButtonListItem = styled.li({
  display: 'inline-block',
  borderRight: `1px solid ${DIVIDER_COLORS.primary}`,
  paddingRight: SPACING.MEDIUM,

  '&:last-child': {
    borderRight: 0,
    paddingLeft: SPACING.MEDIUM,
    '.button--inline-link': {
      color: COLORS.red,
    },
  },
});

class MagazineEditItem extends Component {
  constructor(props) {
    super(props);

    const {
      item: { customizer },
    } = props;
    const customizations = (customizer && customizer.customizations) || {};

    this.state = {
      title: props.item.title || '',
      excerptText: props.item.excerpt || '',
      clickUrl: customizations.click,
      caption: props.item.caption || '',
      image: null,
      images: [],
      isLoading: false,
      isDirty: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleSet = this.handleSet.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showConfirmDismissModal = this.showConfirmDismissModal.bind(this);
  }

  async componentDidMount() {
    const { item, fetchUrlImages } = this.props;
    if (!item || !item.sourceURL) {
      return;
    }
    try {
      const images = await fetchUrlImages(item.sourceURL, this.props.uid);
      if (images) {
        const processedImages = processFlippusRawImages(images);
        this.setState({ images: processedImages });
      }
    } catch (_e) {
      // Do nothing.
    }
  }

  handleChange(e) {
    const { name } = e.target;
    let value = e.target.value;
    if (name === 'title') {
      value = value.slice(0, TITLE_LENGTH_LIMIT);
    }

    const changes = { [name]: value, isDirty: true };
    this.setState(changes);
  }

  handleChangeImage(imageUrl) {
    this.setState({
      image: imageUrl,
      isDirty: true,
    });
  }

  handleSet(changes) {
    this.setState(Object.assign({ isDirty: true }, changes));
  }

  handleDelete(e) {
    this.props.onDelete(e);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true }, () => {
      const customizations = {
        text: this.state.caption,
        title: this.state.title,
        excerptText: this.state.excerptText,
      };
      if (this.state.clickUrl !== this.props.item.sourceURL) {
        customizations.click = this.state.clickUrl;
      }
      if (this.state.image) {
        customizations.image = this.state.image;
      }
      this.props
        .onSave(customizations)
        .then(this.props.dismissModal)
        .catch(() => this.setState({ isLoading: false }));
    });
  }

  showConfirmDismissModal() {
    this.props.showModal(ConfirmDismiss, {
      name: 'magazine-edit-item',
    });
  }

  render() {
    const {
      item,
      magazine,
      dismissModal,
      updateMagazineCover,
      featureFlags,
      currentUserVerifiedPublisher,
      t,
    } = this.props;
    const { errorMessage, isLoading, isDirty } = this.state;

    const canOverrideItems =
      featureFlags.INTERNAL_PACKAGE_MANAGEMENT || currentUserVerifiedPublisher;

    const postUpdateMagazineCover = () => {
      const coverImageUrl = item.image && largestUrl(item.image);
      magazine.magazineTarget &&
        coverImageUrl &&
        updateMagazineCover(magazine.magazineTarget, item.id, coverImageUrl);
    };

    return (
      <form onSubmit={this.handleSubmit}>
        <ModalWrapper
          name="magazine-edit-item"
          modalTheme={MODAL_THEMES.WINDOW}
          size={MODAL_WINDOW_SIZES.LARGE}
          interceptDismiss={
            this.state.isDirty ? this.showConfirmDismissModal : null
          }
        >
          <div className="modal__content">
            <h1 className="layout-section__header ui-text--title">
              {t('curator_pro_magazine_edit_item_heading')}
            </h1>
            <ItemWrapper>
              <ItemCondensed
                item={item}
                section={magazine}
                shownInList={false}
              />
            </ItemWrapper>
            <ButtonsList>
              <ButtonListItem>
                <Button
                  name={generateCuratorProMagazineButtonName(
                    'edit-item',
                    'delete-item',
                  )}
                  styleModifier={[
                    StyleModifiers.NO_LEFT_PADDING,
                    StyleModifiers.INLINE_LINK,
                  ]}
                  onClick={this.handleDelete}
                  disabled={isLoading}
                >
                  {t('curator_pro_magazine_edit_item_remove_button_label')}
                </Button>
              </ButtonListItem>
              <ButtonListItem>
                <Button
                  name={generateCuratorProMagazineButtonName(
                    'edit-item',
                    'set-magazine-cover',
                  )}
                  styleVariation={StyleVariations.PRIMARY_TEXT}
                  styleModifier={[
                    StyleModifiers.NO_RIGHT_PADDING,
                    StyleModifiers.INLINE_LINK,
                  ]}
                  onClick={postUpdateMagazineCover}
                  disabled={isLoading}
                >
                  {t(
                    'curator_pro_magazine_edit_item_promote_cover_button_label',
                  )}
                </Button>
              </ButtonListItem>
            </ButtonsList>
            <div className="field-block">
              <Label text={t('add-caption')} textClassName="field-block__label">
                <textarea
                  name="caption"
                  placeholder={t('add-caption-placeholder')}
                  onChange={this.handleChange}
                  value={this.state.caption}
                />
              </Label>
            </div>
            {errorMessage && <Message>{errorMessage}</Message>}
            {canOverrideItems && (item.isPost || item.isSection) && (
              <ArticleItemOverrideForm
                url={item.sourceURL}
                title={this.state.title}
                excerptText={this.state.excerptText}
                clickUrl={this.state.clickUrl}
                maxTitleLength={TITLE_LENGTH_LIMIT}
                onChange={this.handleChange}
                onSet={this.handleSet}
              />
            )}
            {item.sourceURL && this.state.images.length >= 2 && (
              <div className="storyboard-item-form__image-selector-grid-wrapper">
                <h2 className="ui-text--supporting">
                  {t('article_set_image')}
                </h2>
                <ImageSelector
                  images={this.state.images}
                  selectedImage={this.state.image || imageUrl(item.image)}
                  onSelect={this.handleChangeImage}
                />
              </div>
            )}
          </div>
          <ModalControls fixedControls>
            <Button
              name={generateCuratorProMagazineButtonName(
                'edit-item',
                'dismiss',
              )}
              label={t('cancel')}
              styleVariation={StyleVariations.SECONDARY}
              onClick={dismissModal}
            />
            <Button
              name={generateCuratorProMagazineButtonName('edit-item', 'submit')}
              type="submit"
              label={t('save')}
              styleVariation={StyleVariations.PRIMARY}
              disabled={isLoading || !isDirty}
            />
          </ModalControls>
        </ModalWrapper>
      </form>
    );
  }
}

MagazineEditItem.propTypes = {
  magazine: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  uid: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  featureFlags: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  updateMagazineCover: PropTypes.func.isRequired,
  currentUserVerifiedPublisher: PropTypes.bool.isRequired,
  fetchUrlImages: PropTypes.func.isRequired,
};

MagazineEditItem.defaultProps = {
  item: null,
};

export default connector(
  connectModal,
  connectAuthentication,
  connectCurrentUser,
  connectFeatureFlags,
  connectCuratorProMagazine,
)(withT(MagazineEditItem));
