import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import {
  STATUS_POST_TITLE,
  UI_TEXT_TYPES,
  FONT_WEIGHTS,
} from 'Style/typography';
import {
  TEXT_COLORS,
  SURFACE_COLORS,
  DIVIDER_COLORS,
  ICON_COLORS,
  DefaultTextColorStyle,
} from 'Style/colors';
import { styleOnlyProps } from 'Style/style-helpers';

// Utils
import { getOriginalItemOnly } from 'Utils/content/item-util';
import { getImageObjectFromSection } from 'Utils/image-util';

// Components
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import PostAttribution from '../attribution/post';
import SocialActions from '../social-actions';
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';
import ItemCardCoreActions from './item-card-core-actions';
import ItemFlipNote from 'Webapp/shared/app/components/item/item-flip-note';
import ItemPostCommentPreview from 'Webapp/shared/app/components/item/item-post-comments-preview';
import Image from 'Webapp/shared/app/components/base/image';
import ImageModal from 'Webapp/shared/app/modals/image';
import ItemLink from 'Webapp/shared/app/components/item/item-link';
import PinnedTag from 'Webapp/shared/app/components/item/card/pinned-tag';
import { StyledAtMention } from 'Webapp/shared/app/components/at-mention';
import CuratorProMagazineItemCardControls, {
  StyledItemCardControlItem,
} from 'Webapp/shared/app/components/curator-pro/magazine/item-card-controls';
import DateFlipped from 'Webapp/shared/app/components/curator-pro/date-flipped';
import { AuthorLink } from 'Webapp/shared/app/components/attribution/author';

import withT from 'ComponentLibrary/hocs/withT';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';
import { ItemActionButtonType } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';
import connectModal from '../../connectors/connectModal';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const itemPostCardShouldForward = styleOnlyProps(
  'isReflip',
  'isFlipComposeImage',
  'isNote',
  'isCuratorProView',
);
const StyledItemPost = styled('article', itemPostCardShouldForward)(
  ({ isReflip, isCuratorProView, isNote }) => ({
    backgroundColor: isNote
      ? SURFACE_COLORS.defaultStandalonePostPrimary
      : SURFACE_COLORS.primary,
    display: 'grid',
    minHeight: '584px',
    borderBottom: 'none',
    height: '100%',
    gridTemplateColumns: 'minmax(0px, 100%)',
    gridTemplateRows: `${isCuratorProView ? 'auto ' : ' '}${
      isReflip ? 'auto ' : ' '
    }${isNote ? ' ' : 'auto '}1fr auto`,
  }),
  ({ isNote }) => {
    if (isNote) {
      return {
        ...DefaultTextColorStyle(true),

        a: DefaultTextColorStyle(true),
      };
    }
  },
);

const StyledItemCaption = styled(ItemFlipNote, itemPostCardShouldForward)(
  ({ isNote }) =>
    isNote && {
      ...DefaultTextColorStyle(true),
      a: DefaultTextColorStyle(true),
    },
  {
    background: 'unset',
    [DefaultItemActionButton]: { color: TEXT_COLORS.secondaryReverse },
  },
);

const StyledItemCardCoreActions = styled(ItemCardCoreActions)({
  marginTop: SPACING.SMALL,
});

/* Header Components */
const HeaderWrapper = styled(
  'header',
  styleOnlyProps('isNote'),
)(({ isNote }) => ({
  backgroundColor: isNote
    ? SURFACE_COLORS.defaultStandalonePostSecondary
    : SURFACE_COLORS.defaultFlipImageSecondary,
  borderRadius: `${SPACING.BASE} ${SPACING.BASE} 0 0`,
  display: 'grid',
  gap: SPACING.LARGE,
  padding: SPACING.LARGE,
}));
const HeaderAuthorRow = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
});

const PostHeader = styled(
  'header',
  styleOnlyProps('isNote'),
)(({ isNote }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  gridTemplateRows: 'auto 1fr',
  padding: isNote ? `0 ${SPACING.MEDIUM}` : '0',
  marginBottom: isNote ? SPACING.BASE4X : '0',
}));

const PostHeaderWrapper = styled(
  'header',
  itemPostCardShouldForward,
)((props) => ({
  padding:
    props.isReflip || props.isFlipComposeImage
      ? SPACING.LARGE
      : `${SPACING.LARGE} ${SPACING.LARGE} 0`,
  [StyledItemCardControlItem]: {
    '&:first-child > button': {
      color: TEXT_COLORS.overlay,
    },
  },
}));
const PostDateFlipped = styled(
  DateFlipped,
  itemPostCardShouldForward,
)(({ isNote }) => {
  DefaultTextColorStyle(isNote);
});

const FlippedComposedImageWrapper = styled(ItemLink)({ display: 'grid' });
const StyledFlippedComposedImage = styled(Image)({
  minHeight: '240px',
  maxHeight: '296px',
  cursor: 'pointer',
  gridArea: '1 / 1',
  zIndex: 1,
});
const ImageOverlayPinnedTag = styled(PinnedTag)({
  gridArea: '1 / 1',
  zIndex: 1,
  margin: `auto ${SPACING.LARGE} ${SPACING.LARGE}`,
});

const PostOverflowActions = styled(
  SocialActions,
  itemPostCardShouldForward,
)(({ isNote }) => ({
  marginLeft: 'auto',

  '.meatballs-menu-icon__meatball': {
    backgroundColor: isNote ? ICON_COLORS.overlay : ICON_COLORS.primary,
  },
}));

const PostBody = styled(
  'div',
  itemPostCardShouldForward,
)(({ isFlipComposeImage }) => ({
  backgroundColor: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  gap: isFlipComposeImage ? SPACING.LARGE : SPACING.XLARGE,
  padding: `${SPACING.BASE4X} ${SPACING.BASE4X} ${SPACING.BASE} ${SPACING.BASE4X}`,
}));

const CardAttribution = styled(PostAttribution)(
  UI_TEXT_TYPES.SUPPORTING,
  ({ avatarSize }) => ({
    minWidth: '0',
    '.author-avatar': {
      height: `${avatarSize}px`,
      width: `${avatarSize}px`,
    },
    '.author-avatar__image': {
      border: `2px solid ${DIVIDER_COLORS.primary}`,
    },
    [AuthorLink]: {
      fontWeight: FONT_WEIGHTS.SEMIBOLD,
    },
  }),

  ({ isNote }) => {
    if (isNote) {
      return {
        ...DefaultTextColorStyle(true),
        a: DefaultTextColorStyle(true),
      };
    }
  },
);

/* Main Status Components */
const StyledPostText = styled(ItemFlipNote, itemPostCardShouldForward)(
  STATUS_POST_TITLE,
  {
    a: DefaultTextColorStyle(false),
    [StyledAtMention]: { marginBottom: '8px' },
  },

  ({ isNote }) => {
    if (isNote) {
      return {
        fontSize: '24px',
        ...DefaultTextColorStyle(true),
        a: DefaultTextColorStyle(true),
      };
    }
  },
);

const PostCommentPreview = styled(
  ItemPostCommentPreview,
  itemPostCardShouldForward,
)(({ isFlipComposeImage }) => {
  if (isFlipComposeImage) {
    return { paddingTop: SPACING.BASE4X };
  }
});

const PostFooter = styled.footer({
  display: 'grid',
  alignContent: 'end',
  padding: `0 ${SPACING.LARGE} ${SPACING.LARGE}`,
});

const ItemPost = ({
  item,
  section,
  showModal,
  featureFlags,
  isCuratorProView,
  t,
  lang,
}) => {
  if (!item.isStatus && !item.isFlipComposeImage) {
    return null;
  }
  const { isNote, isFlipComposeImage } = item;

  const originalItem = getOriginalItemOnly(item);

  const isReflip = !!originalItem;

  // We may want to render the "original" item, as in the case of
  // "status" items
  const itemToRender = originalItem || item;
  const { sanitizedTitle } = itemToRender;

  // Some items are incomplete - do not render when there is missing content
  if (
    sanitizedTitle === null &&
    item.excerpt === null &&
    !itemToRender.isImage
  ) {
    return null;
  }
  // DO NOT target itemToRender/original item. Actions should be targeted to the "reflip"
  const imageToRender = getImageObjectFromSection(item);

  const promptImageModal = () => {
    showModal(ImageModal, {
      item,
    });
  };

  let numLines = 10;
  if (isReflip) {
    numLines -= 1;
  }
  if (isFlipComposeImage) {
    numLines -= 4;
  }

  const PostHeaderComponent = (
    <PostHeader isNote={isNote}>
      <CardAttribution
        item={itemToRender}
        section={section}
        showMagazineAttribution={false}
        avatarSize={
          featureFlags.DISPLAY_COMMENT_BUBBLE || item.isImage ? 44 : 32
        }
        shouldShowTime
        isNote={isNote}
      />
      {!isCuratorProView && (
        <PostOverflowActions
          section={section}
          item={itemToRender}
          itemCardVariant
          buttonSet={[]}
          isNote={isNote}
          actionSheetButtonSet={[
            ItemActionButtonType.REPORT,
            !isReflip && ItemActionButtonType.REMOVE_FROM_MAGAZINE,
            !isReflip && ItemActionButtonType.PIN_FLIP,
          ]}
          anchoredDialogStyleVariation={
            AnchoredDialog.styleVariations.MENU_FULL_WIDTH
          }
        />
      )}
    </PostHeader>
  );
  return (
    <StyledItemPost
      isReflip={isReflip}
      isNote={isNote}
      isCuratorProView={isCuratorProView}
    >
      {isCuratorProView && (
        <PostHeaderWrapper
          isReflip={isReflip}
          isFlipComposeImage={isFlipComposeImage}
        >
          <PostHeader>
            <PostDateFlipped item={item} lang={lang} />
            <CuratorProMagazineItemCardControls item={item} section={section} />
          </PostHeader>
        </PostHeaderWrapper>
      )}
      {isReflip && (
        <HeaderWrapper isNote={isNote}>
          <StyledItemCaption
            item={item}
            isItemCaption
            isOverlay={isNote}
            isNote={isNote}
            isStandalonePost
          />
          <HeaderAuthorRow>
            <CardAttribution item={item} section={section} isNote={isNote} />
            {!isCuratorProView && (
              <PostOverflowActions
                section={section}
                item={item} // DO NOT target itemToRender/original item. Actions should be targeted to the "reflip"
                itemCardVariant
                buttonSet={[]}
                isNote={isNote}
                actionSheetButtonSet={[
                  ItemActionButtonType.REPORT,
                  ItemActionButtonType.REMOVE_FROM_MAGAZINE,
                  ItemActionButtonType.PIN_FLIP,
                ]}
                anchoredDialogStyleVariation={
                  AnchoredDialog.styleVariations.MENU_FULL_WIDTH
                }
              />
            )}
          </HeaderAuthorRow>
        </HeaderWrapper>
      )}
      {isFlipComposeImage && (
        <FlippedComposedImageWrapper
          item={item} // The "image" for a flip compose image is on the item, not the original.
          section={section}
          disableAnchorLink
          onClick={() => {
            promptImageModal(imageToRender);
          }}
        >
          <StyledFlippedComposedImage
            image={imageToRender}
            alt={item?.caption || t('flip_compose_image_title')}
            crop
            useDumbCroppedImage
          />
          <ImageOverlayPinnedTag item={itemToRender} section={section} />
        </FlippedComposedImageWrapper>
      )}
      <PostBody className="post__body" isFlipComposeImage={isFlipComposeImage}>
        {!item.isFlipComposeImage && <PinnedTag item={item} />}
        <StyledPostText
          item={item}
          isNote={isNote}
          numLines={numLines}
          isStandalonePost
          isOverlay={isNote}
          overrideBackgroundColor={
            isNote
              ? SURFACE_COLORS.defaultStandalonePostPrimary
              : SURFACE_COLORS.primary
          }
        />
        {(featureFlags.DISPLAY_COMMENT_BUBBLE || item.isImage) &&
          PostHeaderComponent}
        {featureFlags.DISPLAY_COMMENT_BUBBLE && (
          <PostCommentPreview
            isFlipComposeImage={isFlipComposeImage}
            item={item}
          />
        )}
      </PostBody>
      {!featureFlags.DISPLAY_COMMENT_BUBBLE && isNote && PostHeaderComponent}
      <PostFooter>
        <StyledItemCardCoreActions
          section={section}
          item={item} // DO NOT target itemToRender/original item. Actions should be targeted to the "reflip"
          buttonSet={BUTTON_SETS.NEW_ITEM_CARD}
          showSocialMetrics
          isOverlay={isNote}
        />
      </PostFooter>
    </StyledItemPost>
  );
};
ItemPost.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  isPhone: PropTypes.bool.isRequired,
  truncateTitleLength: PropTypes.number,
  truncateExcerptLength: PropTypes.number,
  showExcerpt: PropTypes.bool,
  shownInList: PropTypes.bool,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  isCuratorProView: PropTypes.bool,
  featureFlags: PropTypes.object.isRequired,
};

ItemPost.defaultProps = {
  section: null,
  truncateTitleLength: null,
  truncateExcerptLength: null,
  showExcerpt: false,
};

export default connector(
  connectResponsive,
  connectLanguage,
  connectModal,
  connectFeatureFlags,
)(withT(ItemPost));
