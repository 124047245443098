import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

// Utils
import Attribution from 'Utils/content/attribution';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

// Components
import ItemCondensed from './item/item-condensed';
import Item from './item';
import SectionItemDisplay from 'Webapp/shared/app/containers/section-item-display';
import PostAttribution from './attribution/post';
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import WebLink from './web-link';
import withT from 'ComponentLibrary/hocs/withT';

const StyledPostGroupTitle = styled.h2(UI_TEXT_TYPES.TITLE, {
  marginBottom: SPACING.MEDIUM,
});

function Group({ section, item, displayType, t }) {
  const { title, items } = item;

  if (items && items.length < 3) {
    // Default to card layout if we only have 1 or 2 items in the group,
    // rendering just the first item if we have 2.
    return <Item section={section} item={items[0]} displayType={displayType} />;
  }

  const flipper = Attribution.getFlipper(item);
  const magazine = Attribution.getMagazine(item);
  const moreStoriesLabel = flipper
    ? `${t('view_more_from')} ${title}`
    : t('view_more_stories');

  let urlLink;
  if (magazine?.canonicalPath) {
    urlLink = magazine.canonicalPath;
  } else {
    const sectionLinkTopic = Attribution.getFirstSectionLinkByType(
      item,
      'topic',
    );
    urlLink = sectionLinkTopic?.canonicalPath;
  }

  return (
    <div className="post post--group post-feed-grid">
      {flipper ? (
        <header className="post__header">
          <PostAttribution item={item} section={section} />
        </header>
      ) : (
        urlLink && (
          <header className="post__header">
            <StyledPostGroupTitle>
              <WebLink
                href={urlLink}
                name="group-item-link-to-group"
                navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
              >
                {title}
              </WebLink>
            </StyledPostGroupTitle>
          </header>
        )
      )}
      <ul className="post__body">
        {items.slice(0, 4).map((item) => (
          <li key={`item=${item.id}`}>
            <SectionItemDisplay item={item}>
              <ItemCondensed section={section} item={item} />
            </SectionItemDisplay>
          </li>
        ))}
      </ul>
      {urlLink && (
        <footer className="post__footer">
          <Button
            name="group-item-group-link"
            className="post--group__see-more"
            navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
            href={urlLink}
            label={moreStoriesLabel}
            styleModifier={[StyleModifiers.BLOCK]}
          />
        </footer>
      )}
    </div>
  );
}

Group.propTypes = {
  section: PropTypes.object,
  item: PropTypes.object.isRequired,
  displayType: PropTypes.oneOf(['card', 'condensed']),
  t: PropTypes.func.isRequired,
};

Group.defaultProps = {
  section: null,
};

export default withT(Group);
