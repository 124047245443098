import React from 'react';
import PropTypes from 'prop-types';

// Components
import SocialAttribution from '../../attribution/social-attribution';
import SourceAttribution from '../../attribution/source';

function ItemCardSourceAttribution({ item, section, ...props }) {
  return item.isTwitter ? (
    <SocialAttribution item={item} section={section} {...props} />
  ) : (
    <SourceAttribution item={item} section={section} {...props} />
  );
}

ItemCardSourceAttribution.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
};

export default ItemCardSourceAttribution;
