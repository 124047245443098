import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getWindow from 'Utils/get-window';
import { WindowScroll } from 'Utils/window-scroll';

class SectionItemDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.handleViewportChange = this.handleViewportChange.bind(this);
    this.scroller = new WindowScroll(this.handleViewportChange);
    this.ref = React.createRef();
    this.shouldReport = true;
  }

  componentDidMount() {
    this.scroller.subscribe();
  }

  componentWillUnmount() {
    this.scroller.unsubscribe();
  }

  handleViewportChange() {
    if (this.ref && this.ref.current) {
      const child = this.ref.current.children[0];
      if (child) {
        const rect = child.getBoundingClientRect();
        const midpoint = rect.top + rect.height / 2;
        if (midpoint > 0 && midpoint < getWindow().innerHeight) {
          if (this.shouldReport) {
            const {
              usageTrackDisplayItem,
              usageIncrementSectionHeartbeatItemsDisplayed,
              item,
            } = this.props;
            usageTrackDisplayItem(item);
            usageIncrementSectionHeartbeatItemsDisplayed();
            this.shouldReport = false;
          }
        } else {
          this.shouldReport = true;
        }
      }
    }
  }

  render() {
    return (
      <div
        ref={this.ref}
        className={this.props.className}
        style={this.props.postStyleOverride}
      >
        {this.props.children}
      </div>
    );
  }
}

SectionItemDisplay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  postStyleOverride: PropTypes.object,
  usageIncrementSectionHeartbeatItemsDisplayed: PropTypes.func.isRequired,
  usageTrackDisplayItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default SectionItemDisplay;
