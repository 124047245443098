/* eslint react/no-danger: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { COLORS } from 'Style/colors';

// Components
import WebLink from './web-link';

const kTwitterBaseURL = 'https://twitter.com';

const StyledTwitterLink = styled(WebLink)({
  color: COLORS.red,
});

function linkMention(chunk) {
  const match = chunk.match && chunk.match(/@\w+/iu);
  if (match) {
    const stripped = match[0].replace(/@/, '');
    const url = `${kTwitterBaseURL}/${stripped}`;
    return (
      <StyledTwitterLink key={chunk} href={url}>
        {chunk}
      </StyledTwitterLink>
    );
  }
  return chunk;
}

function linkHashtag(chunk) {
  const match = chunk.match && chunk.match(/(#\w+)/iu);
  if (match) {
    const stripped = match[0].replace(/#/, '');
    const url = `${kTwitterBaseURL}/hashtag/${stripped}`;
    return (
      <StyledTwitterLink key={chunk} href={url}>
        {chunk}
      </StyledTwitterLink>
    );
  }
  return chunk;
}

function linkLink(chunk) {
  const match = chunk.match && chunk.match(/https?:\/\/.+/iu);
  if (match) {
    return (
      <StyledTwitterLink key={chunk} href={match[0]}>
        {match[0]}
      </StyledTwitterLink>
    );
  }
  return chunk;
}

function linkUnlinked(url, components) {
  const firstTextIndex = components.findIndex((c) => typeof c === 'string');
  if (firstTextIndex === -1) {
    return components;
  }
  let lastIndex = firstTextIndex;
  while (typeof components[lastIndex + 1] === 'string') {
    lastIndex = lastIndex + 1;
  }
  const text = components.slice(firstTextIndex, lastIndex + 1).join(' ');
  components.splice(
    firstTextIndex,
    lastIndex + 1 - firstTextIndex,
    <WebLink key={text} href={url}>
      {text}
    </WebLink>,
  );
  return linkUnlinked(url, components);
}

function TwitterText({ text, url }) {
  const split = text.match(/[^\s]+\s*/g);
  const components = split.map(linkMention).map(linkHashtag).map(linkLink);
  return url ? linkUnlinked(url, components) : components;
}

TwitterText.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
};

TwitterText.defaultProps = {
  url: null,
};

export default TwitterText;
