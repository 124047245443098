import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { truncate } from 'Style/truncate';
import { CONTENT_BODY, CONTENT_STORY_HEADING_TYPES } from 'Style/typography';
import { TEXT_COLORS, SURFACE_COLORS } from 'Style/colors';

// Utils
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { getImageObjectFromSection } from 'Utils/image-util';
// Components
import SectionLink from '../section-link';
import Image, { THEMES } from '../base/image';
import Badge from '../badge';
import PostAttribution from '../attribution/post';
import SocialActions from '../social-actions';
import Header from '../base/header';
import CuratorAttribution from 'Webapp/shared/app/components/attribution/curator';
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';
import ItemCardCoreActions from './item-card-core-actions';

import ItemFlipNote from 'Webapp/shared/app/components/item/item-flip-note';
import CuratorProMagazineItemCardControls from 'Webapp/shared/app/components/curator-pro/magazine/item-card-controls';
import DateFlipped from 'Webapp/shared/app/components/curator-pro/date-flipped';

import withT from 'ComponentLibrary/hocs/withT';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';
import PinnedTag from 'Webapp/shared/app/components/item/card/pinned-tag';

const StyledItemMagazine = styled.article({
  display: 'grid',
  minHeight: '584px',
  borderBottom: 'none',
  height: '100%',
  gridTemplateColumns: 'minmax(0px, 100%)',
  gridTemplateRows: 'auto 1fr',
});
/* Header Components */
const HeaderWrapper = styled('header', {
  shouldForwardProp: (prop) => prop !== 'hasCaption',
})((props) => ({
  backgroundColor: props.hasCaption ? SURFACE_COLORS.secondary : 'inherit',
  borderRadius: `${SPACING.BASE} ${SPACING.BASE} 0 0`,
  display: 'grid',
  gap: SPACING.LARGE,
  padding: SPACING.LARGE,
}));
const StyledHeader = styled.header({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  '.post-attribution': {
    marginBottom: 0,
  },
  '.post-attribution--empty': {
    display: 'none',
  },
});

const PostOverflowActions = styled(SocialActions)({
  marginLeft: 'auto',
});

const StyledPostBody = styled.div({
  position: 'relative',
  display: 'grid',
  gridTemplateRows: '1fr 98px',
  alignItems: 'end',
});
const LinkedMagazineImage = styled(SectionLink)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: '0px',
  color: TEXT_COLORS.overlay,
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: `0 0 ${SPACING.MEDIUM} ${SPACING.MEDIUM}`,
  },
  '&::after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    top: '0px',
    height: '100%',
    width: '100%',
    zIndex: '1',
    borderRadius: `0 0 ${SPACING.MEDIUM} ${SPACING.MEDIUM}`,
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 27.53%, rgba(0, 0, 0, 0.9) 100%)',
  },
});

const StyledOverlayContents = styled.div({
  zIndex: '3',
  padding: `0 ${SPACING.LARGE}`,
  color: TEXT_COLORS.overlay,
});
const MagazineDescription = styled.p(CONTENT_BODY, truncate('100%', 5), {
  '& >.internal-link': {
    color: TEXT_COLORS.overlay,
  },
});

const StyledMagazineBadgeContainer = styled.div({
  paddingBottom: SPACING.LARGE,
  color: TEXT_COLORS.overlay,
});

const StyledMagazineCardFooter = styled.footer({
  borderRadius: `0 0 ${SPACING.BASE} ${SPACING.BASE}`,
  padding: `${SPACING.BASE4X} ${SPACING.LARGE} ${SPACING.LARGE}`,
  display: 'flex',
  zIndex: '1',
  width: '100%',
});

const FooterContentWrapper = styled.div({
  position: 'relative',
  width: '100%',
});

const StyledMagazineTitleWrapper = styled(Header)({
  marginBottom: `${SPACING.LARGE}`,
  color: TEXT_COLORS.overlay,
  overflowWrap: 'break-word',
});

const MagazineTitleLink = styled(SectionLink)(
  CONTENT_STORY_HEADING_TYPES.LARGE_OVERLAY,
);
const MagazineCurator = styled(CuratorAttribution)({
  color: TEXT_COLORS.overlay,
  marginBottom: SPACING.LARGE,
});

const StyledPinnedTag = styled(PinnedTag)({
  position: 'absolute',
  left: SPACING.LARGE,
  top: SPACING.LARGE,
});

function ItemMagazine({ item, section, isPhone, isCuratorProView, lang, t }) {
  const itemSection = item.section;
  if (!itemSection) {
    return null;
  }
  const { title, description, isFromContentGuide } = itemSection;

  const imageToRender = getImageObjectFromSection(itemSection);

  const showText = !isFromContentGuide || !isPhone;
  const showTitle = imageToRender && imageToRender.showTitle;
  const renderTitle = showTitle || typeof showTitle === 'undefined';

  const sectionLinkProps = {
    item,
    section: itemSection,
    navFromEventName: USAGE_EVENT_NAMES.SECTION_ENTER,
  };

  const headerProps = {
    ...sectionLinkProps,
    className: 'header-text-container',
  };

  const descriptionToRender = description;

  const MagazineTitle = (
    <StyledMagazineTitleWrapper>
      <StyledMagazineBadgeContainer>
        <Badge isOverlay>{t('magazine')}</Badge>
      </StyledMagazineBadgeContainer>
      <MagazineTitleLink {...sectionLinkProps}>{title}</MagazineTitleLink>
    </StyledMagazineTitleWrapper>
  );

  const MagazineBody = (
    <React.Fragment>
      <MagazineCurator item={item} avatarSize={32} />
      <MagazineDescription>
        <SectionLink {...sectionLinkProps}>{descriptionToRender}</SectionLink>
      </MagazineDescription>
    </React.Fragment>
  );

  const MagazineBackgroundImage = (
    <LinkedMagazineImage {...headerProps}>
      <Image image={imageToRender} alt={title} variant={THEMES.large} />
    </LinkedMagazineImage>
  );

  const MagazineOverlay = (
    <StyledOverlayContents>
      <StyledPinnedTag item={item} />
      {renderTitle && MagazineTitle}
      {showText && MagazineBody}
    </StyledOverlayContents>
  );

  return (
    <StyledItemMagazine>
      <HeaderWrapper hasCaption={!!item.caption}>
        {isCuratorProView && (
          <StyledHeader>
            <DateFlipped item={item} lang={lang} />
            <CuratorProMagazineItemCardControls item={item} section={section} />
          </StyledHeader>
        )}
        <ItemFlipNote item={item} isItemCaption />
        {!isCuratorProView && (
          <StyledHeader>
            <PostAttribution item={item} section={section} />
            <PostOverflowActions
              section={section}
              item={item}
              itemCardVariant
              buttonSet={[]}
              anchoredDialogStyleVariation={
                AnchoredDialog.styleVariations.MENU_FULL_WIDTH
              }
            />
          </StyledHeader>
        )}
      </HeaderWrapper>
      <StyledPostBody>
        {imageToRender && MagazineBackgroundImage}
        {MagazineOverlay}
        <StyledMagazineCardFooter>
          <FooterContentWrapper>
            <ItemCardCoreActions
              section={section}
              item={item}
              buttonSet={BUTTON_SETS.NEW_ITEM_CARD}
              showSocialMetrics
              isOverlay
            />
          </FooterContentWrapper>
        </StyledMagazineCardFooter>
      </StyledPostBody>
    </StyledItemMagazine>
  );
}

ItemMagazine.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  isPhone: PropTypes.bool.isRequired,
  isCuratorProView: PropTypes.bool,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

ItemMagazine.defaultProps = {
  section: null,
  isCuratorProView: false,
};

export default connector(
  connectResponsive,
  connectLanguage,
)(withT(ItemMagazine));
