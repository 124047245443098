import React, { Component } from 'react';
import styled from '@emotion/styled';
import {
  COLORS,
  SURFACE_COLORS,
  TEXT_COLORS,
  DIVIDER_COLORS,
} from 'Style/colors';
import {
  FONTS,
  UI_HEADING_TYPES,
  UI_TEXT_TYPES,
  BODY_TYPES,
} from 'Style/typography';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { POST_MEDIA_SIZING } from 'Style/layout';
import { styleObject } from 'Style/type-helpers';

import Button, { StyleVariations } from 'Webapp/shared/app/components/button';

import connector from 'Utils/connector';

import connectMute, {
  ConnectMuteProps,
} from 'Webapp/shared/app/connectors/connectMute';
import withT from 'ComponentLibrary/hocs/withT';

const MutedArticle = styled.article(
  {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr 62px',
    gridTemplateColumns: 'minmax(0px, 100%)',
    borderBottom: `1px solid ${DIVIDER_COLORS.secondary}`,
    position: 'relative',
    margin: SPACING.LARGE,
  },
  styleObject(
    BREAKPOINTS.phone({
      overflow: 'hidden',
    }),
  ),
);

const Circle = styled.div({
  height: SPACING.BASE4X,
  width: SPACING.BASE4X,
  backgroundColor: SURFACE_COLORS.tertiary,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: SPACING.BASE,
});

interface StyledRectanglesProps {
  height: string;
  width: string;
  marginBottom?: string;
}

const Rectangles = styled.div<StyledRectanglesProps>((props) => ({
  background: SURFACE_COLORS.tertiary,
  height: `${props.height}`,
  width: `${props.width}`,
  marginBottom: props?.marginBottom ? `${props?.marginBottom}` : 0,
}));

const PostAttriubtion = styled.div({
  width: '100%',
});

const StyledHeader = styled.header({
  marginBottom: SPACING.LARGE,
});

const PostMedia = styled.div({
  background: SURFACE_COLORS.tertiary,
  marginBottom: SPACING.LARGE,
});

const PostMediaContainer = styled.div(
  {
    padding: SPACING.BASE4X,
    height: POST_MEDIA_SIZING.mobileHeight,
  },
  styleObject(
    BREAKPOINTS.tabletPortraitUp({
      height: POST_MEDIA_SIZING.tabletHeight,
    }),
  ),
  styleObject(
    BREAKPOINTS.tabletLandscapeUp({
      height: POST_MEDIA_SIZING.tabletLandscapeHeight,
    }),
  ),
  styleObject(
    BREAKPOINTS.desktopUp({
      height: POST_MEDIA_SIZING.desktopHeight,
    }),
  ),
);

const MiddleRowContainer = styled.div({
  marginBottom: SPACING.BASE4X,
});

const MainTitle = styled.div({
  ...UI_HEADING_TYPES.XLARGE,
  color: TEXT_COLORS.tertiary,
  font: FONTS.FONT_FAKT_FLIPBOARD,
  marginBottom: SPACING.LARGE,
});

const SecondTitle = styled.div({
  ...UI_TEXT_TYPES.SUPPORTING_LARGE,
  marginBottom: SPACING.SMALL,
  color: TEXT_COLORS.secondary,
});

const Undo = styled(Button)({
  ...BODY_TYPES.HINT,
  padding: 0,
  background: SURFACE_COLORS.tertiary,
  cursor: 'pointer',
  color: COLORS.red,
  border: 'none',
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    border: 'none',
    color: TEXT_COLORS.secondary,
  },
});

const middleRows = () => {
  const HEIGHT = '24px';
  const rectangles = ['90%', '85%', '70%'];
  const result = rectangles.map((x) => ({ height: HEIGHT, width: x }));
  return result;
};

const lastRow = () => {
  const HEIGHT = '14px';
  const rectangles = ['90%', '85%', '70%'];
  const result = rectangles.map((x) => ({ height: HEIGHT, width: x }));
  return result;
};

interface MutedItemCardPassedProps {
  item: Flipboard.Item;
}

type MutedItemProps = ConnectMuteProps &
  MutedItemCardPassedProps & { t: Flipboard.TFunction };

class MutedItemCard extends Component<MutedItemProps> {
  constructor(props) {
    super(props);
    this.renderTopRow = this.renderTopRow.bind(this);
    this.rendermiddleRows = this.rendermiddleRows.bind(this);
    this.renderlastRow = this.renderlastRow.bind(this);
    this.handleUnMute = this.handleUnMute.bind(this);
  }

  renderTopRow() {
    return (
      <StyledHeader>
        <PostAttriubtion className="post-attribution ui-text--supporting">
          <Circle />
          <Rectangles height={SPACING.LARGE} width="80%" />
        </PostAttriubtion>
      </StyledHeader>
    );
  }

  rendermiddleRows() {
    return (
      <MiddleRowContainer>
        {middleRows().map((x, i) => (
          <Rectangles
            key={i}
            height={x.height}
            width={x.width}
            marginBottom={SPACING.MEDIUM}
          />
        ))}
      </MiddleRowContainer>
    );
  }

  renderlastRow() {
    return (
      <React.Fragment>
        {lastRow().map((x, i) => (
          <Rectangles
            key={i}
            height={x.height}
            width={x.width}
            marginBottom={SPACING.MEDIUM}
          />
        ))}
      </React.Fragment>
    );
  }

  handleUnMute() {
    const { item, unmuteAuthor, unmuteSourceDomain } = this.props;
    if (item.sourceDomain === 'flipboard.com') {
      unmuteAuthor(item.author);
    } else {
      unmuteSourceDomain(item.sourceDomain);
    }
  }

  render() {
    const { item, t } = this.props;
    let label = '';
    if (item.isVideo) {
      label = item?.author?.authorDisplayName;
    } else if (item?.isStoryboard) {
      label = item.authorDisplayName;
    } else {
      label = item.sourceDomain;
    }
    return (
      <MutedArticle>
        {this.renderTopRow()}
        <div className="post__body">
          <PostMedia>
            <PostMediaContainer className="media-container">
              <MainTitle>{t('story_hidden')}</MainTitle>
              <SecondTitle>{t('stories_muted', { label })}</SecondTitle>
              <Undo
                name="undo-mute-item-card"
                label={t('undo')}
                onClick={this.handleUnMute}
                styleVariation={StyleVariations.PRIMARY}
              />
            </PostMediaContainer>
          </PostMedia>
          {this.rendermiddleRows()}
          {this.renderlastRow()}
        </div>
      </MutedArticle>
    );
  }
}

export default connector(connectMute)(withT(MutedItemCard));
