import React from 'react';
import PropTypes from 'prop-types';
import replaceTags from 'Utils/i18n/replace-tags';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';

import withT from 'ComponentLibrary/hocs/withT';

// Components
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/section-tag';
import { getImageObjectFromSection } from 'Utils/image-util';
import Image from 'Webapp/shared/app/components/base/image';
import FollowButton from 'Webapp/shared/app/components/follow-button';
import AuthorAttribution, {
  STYLE_VARIATIONS as AUTHOR_ATTRIBUTION_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/attribution/author';
import MagazineAttribution from 'Webapp/shared/app/components/attribution/magazine';
import WebLink from 'Webapp/shared/app/components/web-link';
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import { getAuthorUrl } from 'Utils/content/flipboard-urls';
import { TEXT_COLORS } from 'Style/colors';

const TopicList = styled.div({
  ...BODY_TYPES.SMALL,
  marginBottom: SPACING.LARGE,
  color: TEXT_COLORS.PRIMARY,
});

const TopicListPreambleDiv = styled.div({
  marginBottom: SPACING.SMALL,
  ...BODY_TYPES.SMALL_EMPHASIS_2,
});

function GeneratedRecommendedMagazineItemCard({ item: magazine, t }) {
  const { topics, title, remoteid, authorImage } = magazine;
  const alt = title ? `${title} - ${t('cover')}` : t('magazine_cover');
  const image = getImageObjectFromSection(magazine);
  const profileUrl = getAuthorUrl(magazine);

  const renderItemTopicList = () => {
    if (!topics) {
      return null;
    }

    const TopicListPreamble = (content) => (
      <TopicListPreambleDiv>{content}</TopicListPreambleDiv>
    );
    const TaggedTopics = () => (
      <React.Fragment>
        {topics
          .map((topic) => (
            <SectionTag
              key={topic.remoteid}
              section={topic}
              styleVariation={TOPIC_TAG_STYLE_VARIATIONS.INHERIT_UNDERLINE}
            />
          ))
          .reduce((acc, sectionTag) => {
            if (acc.length > 0) {
              return acc.concat(', ', sectionTag);
            }
            return [sectionTag];
          }, [])}
      </React.Fragment>
    );

    return (
      <TopicList>
        {replaceTags(t('with_stories_from', { topicList: '' }), {
          topicListPreamble: TopicListPreamble,
          topicTags: TaggedTopics,
        })}
      </TopicList>
    );
  };

  return (
    <div
      className="generated-feed-item-card generated-feed-item-card__magazine"
      key={remoteid}
    >
      <span className="generated-feed-item-card__magazine__title">
        {t('the_more_you_follow')}
      </span>
      <span className="generated-feed-item-card__magazine__subtitle">
        {t('personalize_your_flipboard_magazines')}
      </span>
      <div className="generated-feed-item-card__magazine__image-wrapper">
        <div className="generated-feed-item-card__magazine__image">
          {image && <Image image={image} alt={alt} crop />}
        </div>
        <div className="generated-feed-item-card__magazine__image-overlay">
          <MagazineAttribution magazine={magazine} showBadge isOverlay />
          <div className="generated-feed-item-card__magazine-curator">
            {authorImage !== undefined && (
              <WebLink
                href={profileUrl}
                navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
              >
                <AuthorAvatar author={magazine} />
              </WebLink>
            )}
            <div className="generated-feed-item-card__magazine-curator__text">
              <span>{t('curated_by')}</span>
              <AuthorAttribution
                author={magazine}
                styleVariation={AUTHOR_ATTRIBUTION_STYLE_VARIATIONS.WHITE}
              />
            </div>
          </div>
        </div>
      </div>
      {renderItemTopicList()}
      <div>
        <FollowButton
          namePrefix="generated-feed-item-magazine"
          section={magazine}
          className="magazine__follow-button"
        />
      </div>
    </div>
  );
}

GeneratedRecommendedMagazineItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

GeneratedRecommendedMagazineItemCard.defaultProps = {};

export default withT(GeneratedRecommendedMagazineItemCard);
