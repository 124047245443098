import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { SURFACE_COLORS, TEXT_COLORS } from 'Style/colors';
import { CONTENT_STORY_HEADING_TYPES, CONTENT_BODY } from 'Style/typography';

// Utils
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import StoryboardRenderingUtil from 'Utils/content/storyboard-rendering-util';

// Components
import SectionLink from '../section-link';
import Image from '../base/image';
import Badge from '../badge';
import PostAttribution from '../attribution/post';
import ItemCardCoreActions from './item-card-core-actions';
import SocialActions from '../social-actions';
import Header from '../base/header';
import CuratorAttribution from 'Webapp/shared/app/components/attribution/curator';
import MutedItemCard from 'Webapp/shared/app/components/item/muted-item-card';
import CuratorProMagazineItemCardControls from 'Webapp/shared/app/components/curator-pro/magazine/item-card-controls';
import DateFlipped from 'Webapp/shared/app/components/curator-pro/date-flipped';
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';
import ItemFlipNote from 'Webapp/shared/app/components/item/item-flip-note';
import PinnedTag from 'Webapp/shared/app/components/item/card/pinned-tag';

import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';
/* Header Components */
const HeaderWrapper = styled('header', {
  shouldForwardProp: (prop) => prop !== 'hasCaption',
})((props) => ({
  backgroundColor: props.hasCaption ? SURFACE_COLORS.secondary : 'inherit',
  borderRadius: `${SPACING.BASE} ${SPACING.BASE} 0 0`,
  display: 'grid',
  gap: SPACING.LARGE,
  padding: SPACING.LARGE,
}));

const StyledHeader = styled.header({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  '.post-attribution': {
    marginBottom: 0,
  },
  '.post-attribution--empty': {
    display: 'none',
  },
});
const PostOverflowActions = styled(SocialActions)({
  marginLeft: 'auto',
});

const StyledStoryBoardBody = styled.div(
  {
    backgroundColor: SURFACE_COLORS.defaultStoryboardCard,
    display: 'grid',
    height: '100%',
    gridTemplateRows: '342px auto',
    overlflow: 'hidden',
    '.media-container': {
      height: '100%',
    },
  },
  (props) => props?.backgroundStyle,
);
const BadgeWrapper = styled.div({
  marginTop: 'auto',
  paddingBottom: SPACING.LARGE,
  color: TEXT_COLORS.overlay,
});

const StoryboardImageWrapper = styled(SectionLink)({
  position: 'relative',
  '.cropped-image': {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      zIndex: '1',
      background:
        'linear-gradient(180.02deg, rgba(0, 0, 0, 0) 0.02%, rgba(0, 0, 0, 0.6) 99.98%)',
    },
  },
});

const StoryboardImageOverlay = styled.div({
  marginBottom: `${SPACING.LARGE}`,
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  zIndex: '1',
  padding: SPACING.LARGE,
  display: 'flex',
  flexDirection: 'column',
});

const StoryboardTitle = styled(Header)(
  CONTENT_STORY_HEADING_TYPES.LARGE_OVERLAY,
);

const StyledStoryBoardFooter = styled.footer(
  {
    borderRadius: `0 0 ${SPACING.BASE} ${SPACING.BASE}`,
    padding: `${SPACING.BASE4X} ${SPACING.LARGE} ${SPACING.LARGE}`,
    display: 'flex',
  },
  (props) => props?.backgroundStyle,
);
const FooterContentWrapper = styled.div({
  position: 'relative',
  width: '100%',
});

const StyledHeaderContent = styled.div({
  marginTop: `${SPACING.LARGE}`,
  padding: `0 ${SPACING.LARGE}`,
  paddingBottom: SPACING.BASE,
  '& >.internal-link ': {
    color: TEXT_COLORS.overlay,
  },
});

const Curator = styled(CuratorAttribution)({
  color: TEXT_COLORS.overlay,
});
const StoryboardDescription = styled.p(CONTENT_BODY, {
  color: TEXT_COLORS.overlay,
  '& >.internal-link ': {
    color: TEXT_COLORS.overlay,
  },
});

function ItemNglSectionCard({
  item,
  section,
  isPhone,
  isCuratorProView,
  lang,
  t,
}) {
  const itemSection = item.section;
  if (typeof itemSection === 'undefined' || itemSection === null) {
    return null;
  }

  if (item.recentlyMuted) {
    return <MutedItemCard item={item} section={section} />;
  }

  const {
    title,
    description,
    tileImage,
    brick,
    nglHeaderDescription,
    isNglPromoted,
    isFromContentGuide,
  } = itemSection;
  const image = item.image || itemSection.image;
  const imageToRender = isPhone
    ? brick || image || tileImage
    : image || brick || tileImage;

  const showText = !isFromContentGuide || !(isNglPromoted && isPhone);
  const showTitle = imageToRender && imageToRender.showTitle;
  const renderTitle = showTitle || typeof showTitle === 'undefined';

  const sectionLinkProps = {
    item,
    section: itemSection,
    navFromEventName: USAGE_EVENT_NAMES.SECTION_ENTER,
  };

  const descriptionToRender =
    typeof nglHeaderDescription !== 'undefined'
      ? nglHeaderDescription
      : description;

  const headerTextOverlay = renderTitle && (
    <StoryboardImageOverlay>
      <PinnedTag item={item} />
      <BadgeWrapper>
        <Badge isOverlay>{t('storyboard')}</Badge>
      </BadgeWrapper>
      <StoryboardTitle>{title}</StoryboardTitle>
    </StoryboardImageOverlay>
  );

  const backgroundStyle = StoryboardRenderingUtil.getBackgroundColorStyle(
    image,
    0,
  ) || { backgroundColor: SURFACE_COLORS.defaultStoryboardCard };

  return (
    <article className="post post--card post-grid post--ngl-card">
      <HeaderWrapper hasCaption={!!item.caption}>
        {isCuratorProView && (
          <StyledHeader>
            <DateFlipped item={item} lang={lang} />
            <CuratorProMagazineItemCardControls item={item} section={section} />
          </StyledHeader>
        )}
        <ItemFlipNote item={item} isItemCaption />
        {!isCuratorProView && (
          <StyledHeader>
            <PostAttribution item={item} section={section} />
            <PostOverflowActions
              section={section}
              item={item}
              itemCardVariant
              buttonSet={[]}
              anchoredDialogStyleVariation={
                AnchoredDialog.styleVariations.MENU_FULL_WIDTH
              }
            />
          </StyledHeader>
        )}
      </HeaderWrapper>
      <StyledStoryBoardBody
        className="post--ngl-card__header storyboard-header"
        backgroundStyle={backgroundStyle}
      >
        {imageToRender && (
          <StoryboardImageWrapper {...sectionLinkProps}>
            <Image image={imageToRender} alt={title} crop />
            {headerTextOverlay}
          </StoryboardImageWrapper>
        )}
        {showText && (
          <StyledHeaderContent>
            <Curator item={item} avatarSize={32} />
            <StoryboardDescription>
              <SectionLink {...sectionLinkProps}>
                {descriptionToRender}
              </SectionLink>
            </StoryboardDescription>
          </StyledHeaderContent>
        )}
      </StyledStoryBoardBody>
      <StyledStoryBoardFooter backgroundStyle={backgroundStyle}>
        <FooterContentWrapper>
          <ItemCardCoreActions
            section={section}
            item={item}
            buttonSet={BUTTON_SETS.NEW_ITEM_CARD}
            showSocialMetrics
            isOverlay
          />
        </FooterContentWrapper>
      </StyledStoryBoardFooter>
    </article>
  );
}

ItemNglSectionCard.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  isPhone: PropTypes.bool.isRequired,
  isCuratorProView: PropTypes.bool,
  socialActivity: PropTypes.object,
  lang: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ItemNglSectionCard.defaultProps = {
  section: null,
};

export default connector(
  connectResponsive,
  connectLanguage,
)(withT(ItemNglSectionCard));
