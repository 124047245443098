import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { styleOnlyProps } from 'Style/style-helpers';

import { SPACING } from 'Style/spacing';
import { UI_SUBHEADING_TYPES, BODY_TYPES } from 'Style/typography';
import {
  SURFACE_COLORS,
  DIVIDER_COLORS,
  DefaultTextColorStyle,
  TEXT_COLORS,
  ICON_COLORS,
} from 'Style/colors';
import { componentSelector, styleObject } from 'Style/type-helpers';
import { truncate } from 'Style/truncate';
import { isSectionLinkAuthor } from 'Webapp/predicates';

// Utils
import Attribution from 'Utils/content/attribution';
import { getOriginalItem } from 'Utils/content/item-util';

// Components
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar.js';
import CommentContextMenu from 'Webapp/shared/app/components/comments-context-menu';
import { StyleModifiers } from 'Webapp/shared/app/components/button';
import ItemCommentsButton from 'Webapp/shared/app/components/item-comments-button';
import CommentText from 'Webapp/shared/app/components/comment-text';

import withT from 'ComponentLibrary/hocs/withT';

import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';

import connector from 'Utils/connector';
import { hasSameUserid } from 'Webapp/utils/has-same-property';
import FlapUtil from 'Webapp/utils/content/flap-util';

export type ItemPostCommentPreviewProps = {
  className: string;
  section: Flipboard.Section;
  item: Flipboard.Item;
} & ConnectCurrentUserProps;

enum CommentPreviewPositions {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export type CommentPreviewProps = {
  comment: Flipboard.Comment;
  section: Flipboard.Section;
  item: Flipboard.Item;
  position: CommentPreviewPositions;
  isCuratorProView: boolean;
  t: Flipboard.TFunction;
};

const PreviewWrapper = styled.div({
  display: 'grid',
  width: '100%',
  margin: `auto 0 0 ${SPACING.LARGE}`,
  paddingTop: SPACING.BASE8X,
});

type StyledItemCommentsButtonProps = { isNote: boolean };
const StyledItemCommentsButton = styled(
  ItemCommentsButton,
  styleOnlyProps('isNote'),
)<StyledItemCommentsButtonProps>(({ isNote }) => ({
  marginTop: 'auto',
  color: isNote ? TEXT_COLORS.overlay : TEXT_COLORS.primary,
  '&:hover': {
    '.button--unstyled__children *': {
      ...DefaultTextColorStyle(isNote),
    },
  },
}));

type CommentAuthorProps = {
  avatarSize?: number;
  isNote?: boolean;
};
const CommentAuthor = styled(
  'div',
  styleOnlyProps('avatarSize', 'isNote'),
)<CommentAuthorProps>(
  ({ isNote }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: SPACING.LARGE,
    alignItems: 'center',
    '.author-avatar__image': {
      border: `1px solid ${DIVIDER_COLORS.primary}`,
    },
    '.anchored-dialog-menu__wrapper': {
      verticalAlign: 'middle',
      button: {
        padding: 0,
        width: '16px',
      },
    },

    '.meatballs-menu-icon__meatball': {
      backgroundColor: isNote ? ICON_COLORS.overlay : ICON_COLORS.base,
    },
  }),
  ({ avatarSize }) => {
    if (avatarSize) {
      return {
        '.author-avatar': {
          width: `${avatarSize}px`,
        },
      };
    }
  },
);

const CommentPreviewAttribution = styled.div(
  UI_SUBHEADING_TYPES.LARGE_STANDARD,
  DefaultTextColorStyle(true),
  truncate('100%', 3),
);

const PrevBubbleStyle = ({ isNote }) =>
  styleObject({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: isNote
      ? SURFACE_COLORS.standalonePostCommentPreviewBubble
      : SURFACE_COLORS.flipImageCommentPreviewBubble,
    [componentSelector(CommentPreviewAttribution)]: {
      ...UI_SUBHEADING_TYPES.SMALL,
      color: isNote ? TEXT_COLORS.overlay : TEXT_COLORS.primary,
    },
  });

type PrevBubbleType = { isNote: boolean };
const PreviewPrimary = styled('div', styleOnlyProps('isNote'))<PrevBubbleType>(
  PrevBubbleStyle,
  {
    padding: SPACING.LARGE,
    marginBottom: SPACING.BASE,
    borderRadius: '8px',
    minWidth: '0',
  },
);

const PreviewSecondary = styled('div', styleOnlyProps('isNote'))(
  PrevBubbleStyle,
  {
    borderRadius: '12px',
    opacity: 0.7,
    maxWidth: '200px',
    margin: `0 ${SPACING.BASE6X} ${SPACING.BASE} ${SPACING.LARGE}`,
    padding: `0 ${SPACING.SMALL}`,
    height: '24px',
    [componentSelector(CommentAuthor)]: {
      gap: SPACING.SMALL,
    },
  },
);

const PreviewTertiary = styled('div', styleOnlyProps('isNote'))(
  PrevBubbleStyle,
  {
    borderRadius: '12px',
    margin: `0 ${SPACING.BASE12X} ${SPACING.BASE} ${SPACING.XLARGE}`,
    padding: `0 ${SPACING.SMALL}`,
    maxWidth: '144px',
    opacity: 0.33,
    height: '22px',
    [componentSelector(CommentAuthor)]: {
      gap: SPACING.SMALL,
    },
    [componentSelector(CommentPreviewAttribution)]: {
      fontSize: '10px',
    },
  },
);

const PreviewQuaternary = styled('div', styleOnlyProps('isNote'))(
  PrevBubbleStyle,
  {
    borderRadius: '8px',
    margin: `0 auto 0 ${SPACING.BASE4X}`,
    width: '56px',
    height: '12px',
    opacity: 0.3,
  },
);

type CommentBodyExcerptProps = { isNote?: boolean };
const CommentBodyExcerpt = styled(
  'div',
  styleOnlyProps('isNote'),
)<CommentBodyExcerptProps>(truncate('100%', 3), ({ isNote }) => ({
  ...BODY_TYPES.LARGE_STANDARD,
  color: isNote ? TEXT_COLORS.overlay : TEXT_COLORS.primary,
  marginBottom: SPACING.BASE,
  lineHeight: 1.75,
  whiteSpace: 'pre-wrap',
  overflowWrap: 'anywhere',
}));

const BaseCommentPreview: React.FC<CommentPreviewProps> = ({
  comment,
  section,
  item,
  position,
  t,
}) => {
  let PreviewComponent: StyledComponent<PrevBubbleType> | null;
  let avatarSize = 0;

  switch (position) {
    case CommentPreviewPositions.PRIMARY:
      PreviewComponent = PreviewPrimary;
      avatarSize = 32;
      break;
    case CommentPreviewPositions.SECONDARY:
      PreviewComponent = PreviewSecondary;
      avatarSize = 16;
      break;
    case CommentPreviewPositions.TERTIARY:
      PreviewComponent = PreviewTertiary;
      avatarSize = 14;
      break;
    default:
      // we shouldn't ever get here, but just for consistency.
      PreviewComponent = null;
  }

  if (!PreviewComponent) {
    return PreviewComponent;
  }

  const { isNote } = item;
  // render an empty PreviewComponent if there's no comment.
  // Expected functionality for no relevant comments.
  if (!comment) {
    return <PreviewComponent isNote={isNote} />;
  }

  const author: Flipboard.FlapAuthorSectionLink | undefined =
    comment.sectionLinks.find(isSectionLinkAuthor);

  // there should be an author, but if not render an empty PreviewComponent.
  if (!author) {
    return <PreviewComponent isNote={isNote} />;
  }

  const authorName = Attribution.getAuthorName(author);

  // same as with author
  if (!authorName) {
    return <PreviewComponent isNote={isNote} />;
  }

  const authorText =
    position === CommentPreviewPositions.PRIMARY && !comment.isFollowingAuthor
      ? t('recently_commented', { authorName })
      : authorName;
  const firstFollowing =
    position === CommentPreviewPositions.PRIMARY && comment.isFollowingAuthor;

  return (
    <PreviewComponent isNote={isNote}>
      {firstFollowing && (
        <CommentBodyExcerpt isNote={isNote}>
          <CommentText sectionLinks={comment.sectionLinks}>
            {comment.text}
          </CommentText>
        </CommentBodyExcerpt>
      )}
      <CommentAuthor avatarSize={avatarSize} isNote={isNote}>
        <AuthorAvatar avatarSize={avatarSize} author={author} />
        <CommentPreviewAttribution>
          <span>{authorText}</span>
        </CommentPreviewAttribution>
        {firstFollowing && (
          <CommentContextMenu comment={comment} section={section} item={item} />
        )}
      </CommentAuthor>
    </PreviewComponent>
  );
};

const CommentPreview = withT(BaseCommentPreview);

const ItemPostCommentPreview: React.FC<ItemPostCommentPreviewProps> = ({
  className,
  section,
  item,
  currentUser,
  profile: { followingIds },
}) => {
  const originalItem: Flipboard.Item = getOriginalItem(item);

  const commentsPreview =
    originalItem === item ? originalItem.commentsPreview : item.commentsPreview;

  if (!commentsPreview || commentsPreview.length === 0) {
    return null;
  }

  const followingComments: Array<Flipboard.Comment> = [];
  const otherComments: Array<Flipboard.Comment> = [];

  const uniqAuthorComments = commentsPreview.reduce((acc, comment) => {
    if (!acc.find((c) => hasSameUserid(c, comment))) {
      acc.push(comment);
    }
    return acc;
  }, [] as Array<Flipboard.FlapCommentaryComment>);

  for (const comment of uniqAuthorComments) {
    const commentAuthorSectionLink =
      comment.sectionLinks.find(isSectionLinkAuthor);

    // we only want to display comments that aren't from the current user
    if (!hasSameUserid(currentUser, comment)) {
      // restrict followingComments to comments from accounts the current user follows,
      // and only 1 comment max per unique user.
      const isFollowingAuthor =
        comment.isFollowingAuthor ||
        followingIds.some((id) =>
          FlapUtil.isRemoteIdSectionMatch(id, commentAuthorSectionLink),
        );
      const correctedIsFollowingComment = { ...comment, isFollowingAuthor };
      if (isFollowingAuthor) {
        followingComments.push(correctedIsFollowingComment);
      } else {
        otherComments.push(correctedIsFollowingComment);
      }
    }
  }

  // if there's at least one comment by an account the current user follows,
  // only display following comments. otherwise show up to 1 other comment.
  const comments = [...followingComments, ...otherComments];

  // no comments, no preview bubbles.
  if (comments.length < 1) {
    return null;
  }

  return (
    <StyledItemCommentsButton
      styleModifier={[StyleModifiers.INLINE_LINK]}
      key="comments"
      item={item}
      expandCommentsHeader
      className={className}
      isNote={item.isNote}
    >
      <PreviewWrapper className={className}>
        <CommentPreview
          comment={comments[0]}
          section={section}
          item={item}
          position={CommentPreviewPositions.PRIMARY}
        />
        <CommentPreview
          comment={comments[1]}
          section={section}
          item={item}
          position={CommentPreviewPositions.SECONDARY}
        />
        <CommentPreview
          comment={comments[2]}
          section={section}
          item={item}
          position={CommentPreviewPositions.TERTIARY}
        />
        <PreviewQuaternary isNote={item.isNote} />
      </PreviewWrapper>
    </StyledItemCommentsButton>
  );
};

export default connector<ItemPostCommentPreviewProps>(connectCurrentUser)(
  ItemPostCommentPreview,
);
