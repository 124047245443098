import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TEXT_COLORS } from 'Style/colors';
import { CONTENT_BODY } from 'Style/typography';
// Components
import ItemLink from '../item-link';
import ItemExcerpt from '../excerpt';

import { truncate } from 'Style/truncate';

// Connectors
import connector from 'Utils/connector';
import connectIsArticleRoute from 'Webapp/shared/app/connectors/connectIsArticleRoute';

const StyledPostExcerpt = styled.p(
  truncate('100%', 5),
  CONTENT_BODY,
  TEXT_COLORS.secondary,
);
function ItemCardExcerpt({ item, section, shownInList, isArticleRoute }) {
  if (item.isTwitter) {
    return null;
  }

  // don't show excerpt if identical to title
  if (item.excerpt === item.sanitizedTitle) {
    return null;
  }

  const excerptElement = <ItemExcerpt item={item} shownInList={shownInList} />;

  if (!excerptElement) {
    return null;
  }

  // Don't render excerpts as links in new preview
  if (isArticleRoute) {
    return (
      <p className="post__excerpt" data-test-id="excerpt-text">
        {excerptElement}
      </p>
    );
  }

  return (
    excerptElement && (
      <StyledPostExcerpt className="post__excerpt" data-test-id="excerpt-text">
        <ItemLink
          item={item}
          section={section}
          shownInList={shownInList}
          className="post-excerpt--text"
        >
          {excerptElement}
        </ItemLink>
      </StyledPostExcerpt>
    )
  );
}

ItemCardExcerpt.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  shownInList: PropTypes.bool,
  truncateExcerptLength: PropTypes.number,
  isArticleRoute: PropTypes.bool.isRequired,
};

ItemCardExcerpt.defaultProps = {
  shownInList: false,
  truncateExcerptLength: 200,
};

export default connector(connectIsArticleRoute)(ItemCardExcerpt);
