import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { UI_TEXT_TYPES } from 'Style/typography';

// Components
import WebLink from '../web-link';

import withT from 'ComponentLibrary/hocs/withT';
const StyledAttributionLink = styled(WebLink)(UI_TEXT_TYPES.SUPPORTING);

function SocialAttribution({ className, section, t }) {
  if (!section) {
    return null;
  }
  const { authorURL, authorDisplayName, authorUsername } = section;
  if (!authorURL) {
    return null;
  }

  return (
    <StyledAttributionLink
      className={className}
      href={authorURL}
      target="_blank"
    >
      {t('author_on_twitter', {
        authorDisplayName: authorDisplayName || authorUsername,
      })}
    </StyledAttributionLink>
  );
}

SocialAttribution.propTypes = {
  className: PropTypes.string,
  section: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
export default withT(SocialAttribution);
