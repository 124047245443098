import PropTypes from 'prop-types';
// Utils
import sanitizeForReact from 'Utils/sanitize-for-react';
import linkifyText from 'Utils/linkify-text';

function ItemExcerpt({ item, shownInList }) {
  if (item.isTwitter) {
    return null;
  }

  let excerpt =
    item.isSection && item.section ? item.section.description : item.excerpt;

  if (!excerpt) {
    return null;
  }

  if (item.isFirstPartyVideo && !shownInList) {
    // don't strip or truncate first party video so we
    // don't run into problems with linkification
    excerpt = linkifyText(excerpt);
  } else {
    excerpt = sanitizeForReact(excerpt);
  }

  return excerpt;
}

ItemExcerpt.propTypes = {
  item: PropTypes.object.isRequired,
  shownInList: PropTypes.bool,
};

ItemExcerpt.defaultProps = {
  shownInList: false,
};

export default ItemExcerpt;
