import React, { Component } from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { DIVIDER_COLORS } from 'Style/colors';
import PropTypes from 'prop-types';

// Utils
import { generateCuratorProMagazineButtonName } from '../../../../../client/ga/buttons';

// Components
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import ConfirmModal from '../../../modals/confirm-dialog';
import MagazineEditItemModal from '../../../modals/curator-pro/magazine/edit-item';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectCuratorProMagazine from 'Webapp/shared/app/connectors/connectCuratorProMagazine';

const StyledItemCardControls = styled.ul({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${SPACING.SMALL} 0`,
});

const StyledItemCardControlButton = styled(Button)({
  padding: `0 ${SPACING.MEDIUM}`,
  lineHeight: '1',
});

export const StyledItemCardControlItem = styled.li((props) => ({
  display: 'flex',
  marginRight: `1px solid ${DIVIDER_COLORS.primary}`,
  '&:first-child': {
    borderRight: props.isStandalonePost
      ? 'none'
      : `1px solid ${DIVIDER_COLORS.primary}`,
  },
  '&:last-child': {
    justifyContent: 'center',
    [StyledItemCardControlButton]: {
      paddingRight: 0,
    },
  },
}));

class CuratorProMagazineItemCardControls extends Component {
  constructor(props) {
    super(props);

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdateMagazineCover = this.handleUpdateMagazineCover.bind(this);
  }

  handleSave(customizations) {
    return this.props.customizeItem(
      this.props.section.magazineTarget,
      this.props.item,
      customizations,
    );
  }

  handleDelete() {
    this.props.deleteItem(this.props.section.magazineTarget, this.props.item);
    this.props.dismissModal();
  }

  handleClickDelete() {
    const { showModal, item, t } = this.props;
    showModal(ConfirmModal, {
      name: generateCuratorProMagazineButtonName('confirm-delete-item'),
      title: t('delete_item'),
      message: t('confirm_delete_item', { item: item.title }),
      onConfirm: this.handleDelete,
    });
  }

  handleClickEdit() {
    this.props.showModal(MagazineEditItemModal, {
      item: this.props.item,
      magazine: this.props.section,
      onSave: this.handleSave,
      onDelete: this.handleClickDelete,
      updateMagazineCover: this.handleUpdateMagazineCover,
    });
  }

  handleUpdateMagazineCover() {
    this.props.updateMagazineCover(
      this.props.section.magazineTarget,
      this.props.item.oid,
    );
  }

  render() {
    const { isLoading, t, item } = this.props;
    return (
      <StyledItemCardControls>
        <StyledItemCardControlItem isStandalonePost={item.isStandalonePost}>
          <StyledItemCardControlButton
            name={generateCuratorProMagazineButtonName(
              'item-card-controls',
              'remove',
            )}
            onClick={this.handleClickDelete}
            disabled={isLoading}
          >
            {t('remove')}
          </StyledItemCardControlButton>
        </StyledItemCardControlItem>
        {!item.isStandalonePost && (
          <StyledItemCardControlItem>
            <StyledItemCardControlButton
              name={generateCuratorProMagazineButtonName(
                'item-card-controls',
                'edit',
              )}
              styleVariation={StyleVariations.PRIMARY_TEXT}
              styleModifier={[StyleModifiers.NO_RIGHT_PADDING]}
              onClick={this.handleClickEdit}
              tooltip={t('item_cannot_be_edited')}
            >
              {t('edit')}
            </StyledItemCardControlButton>
          </StyledItemCardControlItem>
        )}
      </StyledItemCardControls>
    );
  }
}

CuratorProMagazineItemCardControls.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  customizeItem: PropTypes.func.isRequired,
  updateMagazineCover: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connector(
  connectModal,
  connectCuratorProMagazine,
)(withT(CuratorProMagazineItemCardControls));
