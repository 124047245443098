import React from 'react';
import styled from '@emotion/styled';
import { ICON_COLORS, TEXT_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { styleOnlyProps } from 'Style/style-helpers';
import { componentSelector, styleObject } from 'Style/type-helpers';
import { BREAKPOINTS } from 'Style/breakpoints';

import { StyledIcon } from 'ComponentLibrary/icons/icon';

import { StyledItemMetric } from 'Webapp/shared/app/components/item-actions/item-metric';

import connector from 'Utils/connector';

import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import withItemActions, {
  ItemButtonWithMetric,
} from 'Webapp/shared/app/hocs/withItemActions';
import { ItemActionButtonType } from 'Webapp/enums';

type StyleProps = { isOverlay: boolean };

const IconColorStyle = (isOverlay) => ({
  color: isOverlay ? ICON_COLORS.overlay : ICON_COLORS.emphasis,
});
const IconFocusStyle = { color: ICON_COLORS.emphasis };

const TextColorStyle = (isOverlay) => ({
  color: isOverlay ? TEXT_COLORS.overlay : TEXT_COLORS.primary,
  [componentSelector(StyledItemMetric)]: {
    color: isOverlay ? TEXT_COLORS.overlay : TEXT_COLORS.primary,
  },
  [componentSelector(StyledIcon)]: IconColorStyle(isOverlay),
  '&:focus': {
    color: TEXT_COLORS.primary,
    [componentSelector(StyledItemMetric)]: {
      color: TEXT_COLORS.primary,
    },
    [componentSelector(StyledIcon)]: IconFocusStyle,
  },
});

const CORE_ACTIONS_TABLET_PORTRAIT_ICON_SIZE = '18px';

export const ItemCardSocialIcons = styled(
  'div',
  styleOnlyProps('isOverlay'),
)<StyleProps>(
  ({ isOverlay }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr auto',
    margin: `${SPACING.BASE} 0`,
    [componentSelector(DefaultItemActionButton)]: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0px',
      alignItems: 'center',
      ...TextColorStyle(isOverlay),
    },
    [componentSelector(ItemButtonWithMetric)]: {
      padding: '0px',
      gap: '8px',
      ...TextColorStyle(isOverlay),
    },
  }),
  styleObject(
    BREAKPOINTS.tabletPortraitOnly({
      [componentSelector(StyledIcon)]: {
        width: CORE_ACTIONS_TABLET_PORTRAIT_ICON_SIZE,
        height: CORE_ACTIONS_TABLET_PORTRAIT_ICON_SIZE,
        size: CORE_ACTIONS_TABLET_PORTRAIT_ICON_SIZE,
      },
    }),
  ),
);

type ItemCardCoreActionsPassedProps = {
  isOverlay: boolean;
};
type ItemCardCoreActionsProps = ItemCardCoreActionsPassedProps & {
  generateItemActionButtons: () => (() => React.ReactElement)[];
  className?: string;
};

const ItemCardCoreActions: React.FC<ItemCardCoreActionsProps> = ({
  isOverlay,
  generateItemActionButtons,
  className,
}) => {
  const ButtonComponents = generateItemActionButtons().map((b) => b());

  /**
   * The new "design" specifies a specific position for specific icons. Pulling the icons manually.
   * Deferring to the logic in withItemActions for whether an icon is displayable though.
   */
  const buttonByKey = (key) => ButtonComponents.find((bc) => bc.key === key);
  return (
    <ItemCardSocialIcons className={className} isOverlay={isOverlay}>
      {buttonByKey(ItemActionButtonType.LIKE) || <span />}
      {buttonByKey(ItemActionButtonType.COMMENTS) || <span />}
      {buttonByKey(ItemActionButtonType.FLIP) || <span />}
      {buttonByKey(ItemActionButtonType.SHARE) || <span />}
    </ItemCardSocialIcons>
  );
};

export default connector<ItemCardCoreActionsProps>()(
  withItemActions(ItemCardCoreActions),
);
