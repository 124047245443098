import React from 'react';
import PropTypes from 'prop-types';
import { usageItemEnterEventName } from 'Utils/analytics/usage';
import { THEMES as IMAGE_THEMES } from 'Webapp/shared/app/components/base/generic-image';

// Components
import SectionTile from '../../section-tile';
import Media from '../../base/media';
import ImageUnavailable from '../../image-unavailable';

function ItemCardMedia({
  className,
  item,
  section,
  shownInList,
  useCrop,
  windowHeight,
  imageTheme,
  link,
  loadingMode,
  imageOnly,
}) {
  if (item.isStatus) {
    return null;
  }

  // Determine usage nav from event name for item links
  const itemNavFromEventName = usageItemEnterEventName(item);
  const fallbackElementSize = shownInList
    ? ImageUnavailable.fallbackElementSizes.CARD
    : ImageUnavailable.fallbackElementSizes.ARTICLE;

  if (item.isSection) {
    return (
      <div className={`${className} post__media post__media--image`}>
        <SectionTile
          item={item}
          section={item.section}
          shownInFeed
          navFromEventName={itemNavFromEventName}
          includeSectionAttribution
          imageOnly={imageOnly}
        />
      </div>
    );
  }

  return (
    <Media
      className={className}
      item={item}
      section={section}
      useCrop={useCrop}
      windowHeight={windowHeight}
      shownInList={shownInList}
      fallbackElementSize={fallbackElementSize}
      imageTheme={imageTheme}
      link={link}
      loadingMode={loadingMode}
    />
  );
}

ItemCardMedia.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  shownInList: PropTypes.bool,
  useCrop: PropTypes.bool,
  isAmp: PropTypes.bool,
  windowHeight: PropTypes.number,
  imageTheme: PropTypes.oneOf(Object.keys(IMAGE_THEMES)),
  link: PropTypes.bool,
  loadingMode: PropTypes.string,
  imageOnly: PropTypes.bool,
};

ItemCardMedia.defaultProps = {
  useCrop: true,
  isAmp: false,
  windowHeight: null,
  link: true,
};

export default ItemCardMedia;
