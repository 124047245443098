import React from 'react';
import styled from '@emotion/styled';

import { DIVIDER_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';

import ItemCard from './item/item-card';
import GeneratedRelatedTopicItemCard from './item/generated-related-topic-item-card';
import GeneratedRecommendedMagazineItemCard from './item/generated-recommended-magazine-item-card';
import ItemCondensed from './item/item-condensed';
import ItemNglSectionCard from './item/item-ngl-section-card';
import ItemPost from './item/item-post.js';
import ItemMagazine from './item/item-magazine';
import Group from './group';
import SectionItemDisplay from 'Webapp/shared/app/containers/section-item-display';

import connector from 'Webapp/utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';

export const ITEM_DISPLAY_TYPES = {
  CARD: 'card',
  CONDENSED: 'condensed',
};

const StyledListItem = styled(SectionItemDisplay, {
  shouldForwardProp: (prop) =>
    prop !== 'displayType' && prop !== 'isFullWidthComponent',
})((props) => {
  if (props.displayType === ITEM_DISPLAY_TYPES.CONDENSED) {
    return {};
  }
  return {
    border: `2px solid ${DIVIDER_COLORS.primary}`,
    borderRadius: SPACING.MEDIUM,
    overflow: 'hidden',
    height: '100%',
  };
});

interface ItemPassedProps {
  item: Flipboard.Item;
  shownInList?: boolean;
  displayType?: string;
}

type ItemProps = ConnectFeatureFlagsProps & ItemPassedProps;

const Item: React.FC<ItemProps> = (props) => {
  const {
    item,
    shownInList = true,
    displayType = ITEM_DISPLAY_TYPES.CARD,
    featureFlags,
  } = props;
  if (item.isGroup) {
    return <Group {...props} />;
  }

  let ItemComponent;
  let isFullWidthComponent = false;

  switch (displayType) {
    case ITEM_DISPLAY_TYPES.CONDENSED:
      ItemComponent = ItemCondensed;
      break;
    case ITEM_DISPLAY_TYPES.CARD:
    default: {
      if (item.isClientSideFeedItem && (item.isTopic || item.isSmartMagazine)) {
        ItemComponent = GeneratedRelatedTopicItemCard;
      } else if (item.isClientSideFeedItem && item.isMagazine) {
        ItemComponent = GeneratedRecommendedMagazineItemCard;
      } else if (item.isMagazine) {
        ItemComponent = ItemMagazine;
        isFullWidthComponent = true;
      } else if (item.isSection && item.section && item.section.isStoryboard) {
        ItemComponent = ItemNglSectionCard;
        isFullWidthComponent = true;
      } else if (featureFlags.STANDALONE_POST && item.isStandalonePost) {
        ItemComponent = ItemPost;
      } else {
        ItemComponent = ItemCard;
      }
    }
  }

  if (shownInList) {
    return (
      <StyledListItem
        item={item}
        displayType={displayType}
        isFullWidthComponent={isFullWidthComponent}
      >
        <ItemComponent {...props} />
      </StyledListItem>
    );
  }

  return <ItemComponent {...props} />;
};

export default connector<ItemProps>(connectFeatureFlags)(Item);
