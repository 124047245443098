import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';

import { truncate } from 'Style/truncate';
import { CONTENT_STORY_HEADING_TYPES } from 'Style/typography';

// Components
import TwitterText from '../../twitter-text';
import ItemLink from '../item-link';
import Header from '../../base/header';

import connector from 'Utils/connector';
import connectIsArticleRoute from 'Webapp/shared/app/connectors/connectIsArticleRoute';

const StyledHeader = styled(Header)(
  {
    ...truncate('100%', 5),
  },
  CONTENT_STORY_HEADING_TYPES.LARGE,
);
function ItemCardTitle({ item, section, shownInList, isArticleRoute }) {
  const { sanitizedTitle, isSection } = item;

  const titleToRender = sanitizedTitle;

  const titleElement =
    !isSection && titleToRender ? (
      <StyledHeader primary={!shownInList}>
        {item.isTwitter ? (
          <TwitterText text={titleToRender} url={item.sourceURL} />
        ) : (
          <ItemLink
            className={classNames({
              'content-story-heading--large': isArticleRoute,
            })}
            item={item}
            section={section}
            shownInList={shownInList}
            dataTestId="home-feeds"
          >
            {titleToRender}
          </ItemLink>
        )}
      </StyledHeader>
    ) : null;
  return titleElement;
}

ItemCardTitle.propTypes = {
  section: PropTypes.object,
  item: PropTypes.object.isRequired,
  shownInList: PropTypes.bool,
  truncateTitleLength: PropTypes.number,
  isArticleRoute: PropTypes.bool.isRequired,
};

ItemCardTitle.defaultProps = {
  shownInList: false,
  truncatedTitle: null,
};

export default connector(connectIsArticleRoute)(ItemCardTitle);
